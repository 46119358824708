import { render, staticRenderFns } from "./ZoneStatuses.vue?vue&type=template&id=95f6a422&scoped=true&"
import script from "./ZoneStatuses.vue?vue&type=script&lang=ts&"
export * from "./ZoneStatuses.vue?vue&type=script&lang=ts&"
import style0 from "./ZoneStatuses.vue?vue&type=style&index=0&id=95f6a422&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95f6a422",
  null
  
)

export default component.exports